import type { BlockCardsList, CardList } from 'types/block-cards-list';
import React, { useMemo } from 'react';
import { Wrap, Button, Card, Paragraph, CardProps } from '@leafwell/components';
import Figure from './figure';
import HeadingBody from './heading-body';

const CardsListItem: React.FC<
  CardList &
    Pick<BlockCardsList, 'listColor' | 'listNumbered'> & {
      index: number;
      isMinimal?: boolean;
      directionRow?: boolean;
    }
> = ({
  title,
  content,
  image,
  index,
  listColor = 'medium',
  listNumbered = false,
  isMinimal = true,
  directionRow = false,
}) => {
  const noBackgroundColor = listColor === 'none';

  return (
    <Card
      filled={!isMinimal && !noBackgroundColor}
      rounded={!isMinimal}
      size="medium"
      tagName="li"
      variant={
        isMinimal
          ? 'minimal'
          : noBackgroundColor
          ? 'light'
          : (listColor as CardProps['variant'])
      }
      className={['content-start', isMinimal ? 'grid-cols-8' : ''].join(' ')}
    >
      {listNumbered && (
        <Paragraph
          size="small"
          className={[
            isMinimal
              ? 'col-span-1 text-center inline-grid items-center w-7 h-7 rounded-full bg-lilac-300 justify-self-center'
              : directionRow
              ? '!text-2xl'
              : '',
          ].join(' ')}
        >
          {index + 1}
        </Paragraph>
      )}
      {title && (
        <HeadingBody
          level="6"
          title={title}
          className={[
            'font-semibold grid gap-6 grid-cols-inline-form',
            isMinimal ? 'col-span-7' : '',
          ].join(' ')}
          tagName="h3"
        />
      )}
      {content?.length > 0 ? (
        <Paragraph
          dangerouslySetInnerHTML={{ __html: content }}
          className={[
            'mb-2',
            isMinimal ? 'col-span-7' : '',
            isMinimal && listNumbered ? 'col-start-2' : '',
          ].join(' ')}
          tagName="div"
        />
      ) : null}
      {image && (
        <Figure
          {...{
            ...image,
            className: 'col-span-full justify-self-center self-center',
          }}
        />
      )}
    </Card>
  );
};

type CardsListStyles = {
  wrap: string;
  header: string;
  list: string;
};

const CardsList: React.FC<BlockCardsList> = ({
  sectionId,
  direction,
  button,
  content,
  list,
  listColumns = 3,
  listVariant = 'minimal',
  title,
  ...listProps
}) => {
  const isMinimal = listVariant === 'minimal';
  const directionRow = direction === 'row';

  const classNames = useMemo(() => {
    // list needs to grow to make space for extra columns
    const listColumnSpan = 6 + (listColumns - 1);

    const styles = {
      wrap: {
        default:
          'list-cards lg:grid-cols-12 lg:gap-x-12 xl:gap-x-24 lg:gap-y-6',
        row: '',
        column: 'items-start',
      },
      header: {
        default: 'grid gap-6 items-center lg:items-stretch justify-items-start',
        row: 'lg:col-span-full lg:mb-10',
        column: `col-span-12 lg:col-span-${12 - listColumnSpan}`,
      },
      list: {
        default: [
          'grid gap-8',
          listColumns > 1 && !directionRow
            ? `sm:grid-cols-2 md:grid-cols-${listColumns}`
            : '',
          isMinimal ? 'lg:gap-10 xl:gap-x-16' : '',
        ].join(' '),
        row: [
          'lg:col-span-full',
          'md:grid-cols-2',
          `lg:grid-cols-${listColumns}`,
        ].join(' '),
        column: [`col-span-12 lg:col-span-${listColumnSpan}`].join(' '),
      },
    };

    return Object.keys(styles).reduce(
      (result: CardsListStyles, key) => {
        result[key] = `${result[key]} ${styles[key][direction]}`;

        return result;
      },
      {
        wrap: styles.wrap.default,
        header: styles.header.default,
        list: styles.list.default,
      },
    );
  }, [direction, listColumns, isMinimal]);

  return (
    <Wrap id={sectionId} className={classNames.wrap}>
      {title?.length > 0 && (
        <header className={classNames.header}>
          <div className="grid gap-3 lg:gap-6 content-start">
            {title?.length > 0 && <HeadingBody level="2" title={title} />}
            {content && (
              <Paragraph
                dangerouslySetInnerHTML={{ __html: content }}
                size="large"
              />
            )}
          </div>
        </header>
      )}
      <ol className={classNames.list}>
        {list.map((item, index) => {
          return (
            <CardsListItem
              {...item}
              {...listProps}
              key={index}
              index={index}
              isMinimal={isMinimal}
              directionRow={directionRow}
            />
          );
        })}
      </ol>
      {button && (
        <Button
          href={button.url}
          label={button.title}
          rounded={true}
          variant="secondary"
          target={button.target}
          className="w-fit h-fit self-start col-span-full lg:justify-self-center -mt-4 md:mt-0"
        />
      )}
    </Wrap>
  );
};

export default CardsList;
